import { defineComponent } from 'vue';
import BaseIcon from '../atoms/BaseIcon.vue';
import VectorSmall from '../../assets/svg/vector-small.svg';
export default defineComponent({
    name: 'JobTeaser',
    components: { BaseIcon, VectorSmall },
    props: { hideArrow: Boolean },
    setup() {
        return { VectorSmall };
    },
});
